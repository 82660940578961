/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.control-panel {
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 25%;
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1;
}

.heading > img {
  height: auto;
  margin: 10px 0 8px 0;
  width: 150px;
}

#store-list {
  height: 100%;
  overflow: auto;
}

/* #store-list h3 {
  font-size: 1.5rem;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  padding: 10px 50px 10px;
  cursor: pointer;
} */
#store-list .list-entries-container {
  display: none;
  overflow: hidden;
}
#store-list .list-entries-container .list-entry {
  border-bottom: 1px solid #e8e8e8;
  display: block;
  padding: 10px 50px 10px;
  cursor: pointer;
  font-size: 12px;
  /* overflow: hidden; */
}

#store-list .list-entries-container .list-entry:nth-of-type(even) {
  background-color: #f5f5f5;
  cursor: pointer;
}

#store-list .list-entries-container .list-entry:hover,
#store-list .list-entries-container .list-entry.selected {
  background-color: #cdde75;
  border-bottom-color: #cdde75;
  cursor: pointer;
}

.list-entry-search {
  border-bottom: 1px solid #e8e8e8;
  display: block;
  padding: 10px 50px 10px;
  cursor: pointer;
  font-size: 12px;
  /* overflow: hidden; */
}

.list-entry-search:nth-of-type(even) {
  background-color: #f5f5f5;
  cursor: pointer;
}

.list-entry-search:hover,.list-entry-search.selected {
  background-color: #cdde75;
  border-bottom-color: #cdde75;
  cursor: pointer;
}


.store_type_full {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.store_type_full:hover {
  background-color: #cdde75;
}

.store_type_full:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}
/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.table-height {
  height: 65vh;
}
